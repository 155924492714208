import {
  findPurchaseProductPage,
} from "@/utils/api.js";
import { getMaterialGroupList, getPrototypeShopList } from "@/views/prototyZone/api";
import { queryOrg } from "@/views/boothStoreExpenses/boothStoreAddAndEdit/api";
export default {
  components: {},
  data() {
    return {
      nolistImg: require("@/assets/order/noList.png"),
      showList: false,
      windowList: [],
      sortList: [
        {
          name: "发布时间",
          sortName:'salesPreferential.modifiedDate',
          sortStatus: 1 //0默认 1降 2升序
        },
        {
          name: "开始时间",
          sortName:'salesPreferential.startDate ',
          sortStatus: 0 //0默认 1降 2升序
        },
        {
          name: "结束时间",
          sortName:'salesPreferential.endDate',
          sortStatus: 0 //0默认 1降 2升序
        }
      ],
      dataList: [],
      pageLoadFlag: false,
      total: 0,
      totalPages: 0,
      form: this.$form.createForm(this, { name: "horizontal_login" }),
      preperList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/prototyZone",
          name: "prototyZone",
          title: "样机专区"
        }
      ],
      filterFrom: {
        productModel:'',
        "shopInfoId":"",//专卖店ID
        "materialGroupId":"",//物料组ID
        "pageNo":"1",//页码
        "pageSize":"20",//每页数据大小
        orgId:''
      },
      allcheckBox: false,
      isFixed: false,
      cartInfoTip:'',
      maxWordSize: "", //标题最大字数对应宽度
      matklGroup:[],// 筛选物料组
      isShowMoreBtn:true,
      isShowMoreBtn2:true,
      isShowMoreFlag:false,
      isShowMoreFlag2:false,
      shopList:[], // 门店
      shopCode:'', // 门店id
      shopName: '', // 门店name
      materialGroupCode:'',
      ChannelOrderTip:false,
      mesageInfoTips:'',
      orgList:[]
    };
  },
  computed: {
    preperListShow: function() {
      //查看已选
      if (this.allcheckBox) {
        return this.preperList.filter(it => it.userBuy > 0);
      } else {
        return this.preperList;
      }
    },
    chooseProductClass: function() {
      return this.preperList.filter(it => it.userBuy > 0).length;
    },
    allnum: function() {
      return this.preperList.reduce((tot, it) => {
        return tot + (parseInt(it.userBuy) || 0);
      }, 0);
    },
    allTotal: function() {
      let total = this.preperList.reduce((tot, it) => {
        return tot + (it.userBuy || 0) * (it.price || 0);
      }, 0);
      return total.toFixed(2);
    },
    volumeTotal: function() {
      return this.preperList.reduce((tot, it) => {
        return tot + (it.userBuy || 0) * (it.productVolume || 0);
      }, 0);
    }
  },
  created() {
    this.getShopList()
    this.getOrg()
  },
  watch: {
    matklGroup: {
      handler() {
        this.$nextTick(() => {
          //首次初始化数据
          let dataWidth = 0;
          this.maxWordSize = 0;
          for (const idx2 in this.matklGroup) {
            if ( this.matklGroup[idx2].name.length > this.maxWordSize) {
              this.maxWordSize = this.matklGroup[idx2].name.length;
            }
            dataWidth += this.$util.getStrLen(this.matklGroup[idx2].name) * 7 + 13 + 12
          }
          if (dataWidth > 860) {
            this.isShowMoreBtn = true;
          } else {
            this.isShowMoreBtn = false;
          }
          this.maxWordSize = this.maxWordSize * 14 + 14;
        });
      },
      immediate: true,
      deep: true,
    },
    orgList: {
      handler() {
        this.$nextTick(() => {
          //首次初始化数据
          let dataWidth = 0;
          this.maxWordSize = 0;
          for (const idx2 in this.orgList) {
            if ( this.orgList[idx2].name.length > this.maxWordSize) {
              this.maxWordSize = this.orgList[idx2].name.length;
            }
            dataWidth += this.$util.getStrLen(this.orgList[idx2].name) * 7 + 13 + 12
          }
          if (dataWidth > 860) {
            this.isShowMoreBtn2 = true;
          } else {
            this.isShowMoreBtn2 = false;
          }
          this.maxWordSize = this.maxWordSize * 14 + 14;
        });
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 获取组织
    getOrg(){
      queryOrg({}).then(res=>{
        this.orgList = res.data.list
        this.orgList =  this.getNewList(this.orgList)
      })
    },
    // 获取门店
    async getShopList() {
      await getPrototypeShopList({}).then(res => {
        this.shopList = res.data.data
        this.filterFrom.shopInfoId = this.shopList[0].code;
        // "desc": "709758301"//专卖店门店CIS编
        let obj = this.shopList.find((i) => {//listData就是上面的数据源
          return i.code == this.filterFrom.shopInfoId//筛选出匹配数据
        });
        this.shopCisCode = obj.desc;
        this.getMatkl({shopInfoId: this.filterFrom.shopInfoId})
      }).catch(error=>{
        console.log(error);
      })
    },
    // 更改立项组织
    async handleChangeshopList(value, e) {
      this.filterFrom.shopInfoId = value;
      this.shopName = e.key;
      let obj = this.shopList.find((i) => {//listData就是上面的数据源
        return i.code === this.filterFrom.shopInfoId;//筛选出匹配数据
      });
      this.shopCisCode = obj.desc;
      await this.getMatkl({shopInfoId: this.filterFrom.shopInfoId})
    },
    // 获取物料组
    // getMatkl(data){
    //   return getMaterialGroupList(data)
    // },
    getMatkl(data){
      getMaterialGroupList(data).then(res=>{
        if(res.data.code == 0 ) {
          this.matklGroup = []
          this.matklGroup = res.data.code == 0 ? res.data.data : []
          this.matklGroup =  this.getNewList(this.matklGroup)
          this.filterFrom.materialGroupId = this.matklGroup[0].id;

          this.preperListShow = [];
          this.filterFrom = { ...this.filterFrom , pageNo: 1 };
          this.getProductList();
        } else {
          this.$message.warning(res.data.msg)
        }
      }).catch(error=>{
        console.log(error);
      })
    },
    // 搜索
    onSearchOrderCode() {
      this.preperList = [];
      this.filterFrom = { ...this.filterFrom , pageNo: 1 };
      this.getProductList();
    },
    //获得新格式的 dictList 数组
    getNewList(paramList) {
      let newArr = [],
        data = {
          id: "",
          code:'',
          name: "全部",
          isActive: true,
        };
      for (let key in paramList) {
        newArr.push({
          id: paramList[key].id,
          code:paramList[key].code,
          name: paramList[key].name,
          isActive: false,
        });
      }
      newArr.unshift(data);
      return newArr;
    },
    //单行展开收起
    isLineViewFun() {
      this.isShowMoreFlag = !this.isShowMoreFlag;
    },
    //单行展开收起
    isLineViewFun2() {
      this.isShowMoreFlag2 = !this.isShowMoreFlag2;
    },
    // 选择供应商
    setActiveGFun(itemIdx) {
      //判断点击时是否是选中状态-》选中|未选中
      if (this.orgList[itemIdx].isActive) {
        //选中状态
        //判断该选中按钮是否是全部选项 是-》不做操作 否-》取消选中
        if (this.orgList[itemIdx].name == "全部") {
          return;
        } else {
          //取消选中当前项 当list全部未选中时 选中’全部‘
          this.orgList[itemIdx].isActive = false;
          this.$forceUpdate()
          let count = 0;
          for (let item of this.orgList) {
            if (item.isActive) {
              count++;
            }
          }
          if (!count) {
            this.orgList[itemIdx].isActive = true;
            this.$forceUpdate()
          }
        }
      } else {
        //未选中状态
        if (this.orgList[itemIdx].name == "全部") {
          //取消当前list全部选中 再选中全部
          for (let item of this.orgList) {
            item.isActive = false;
          }
          this.orgList[itemIdx].isActive = true;
          this.filterFrom.orgId = '';
          this.preperListShow = [];
          this.filterFrom = { ...this.filterFrom , pageNo: 1 };
          this.getProductList();
          this.$forceUpdate()
          //   获取数据
        }else {
          this.orgList[0].isActive = false;
          for (let item of this.orgList) {
            item.isActive = false;
          }
          this.orgList[itemIdx].isActive = true;

          this.filterFrom.orgId = this.orgList[itemIdx].code
          // this.orgCode = this.orgList[itemIdx].code,
          this.preperListShow = [];
          this.filterFrom = { ...this.filterFrom , pageNo: 1 };
          this.getProductList();
        }
      }
    },
    // 选择物料组
    setActiveFun(itemIdx) {
      //判断点击时是否是选中状态-》选中|未选中
      if (this.matklGroup[itemIdx].isActive) {
        //选中状态
        //判断该选中按钮是否是全部选项 是-》不做操作 否-》取消选中
        if (this.matklGroup[itemIdx].name == "全部") {
          return;
        } else {
          //取消选中当前项 当list全部未选中时 选中’全部‘
          this.matklGroup[itemIdx].isActive = false;
          this.$forceUpdate()
          let count = 0;
          for (let item of this.matklGroup) {
            if (item.isActive) {
              count++;
            }
          }
          if (!count) {
            this.matklGroup[itemIdx].isActive = true;
            this.$forceUpdate()
          }
        }
      } else {
        //未选中状态
        if (this.matklGroup[itemIdx].name == "全部") {
          //取消当前list全部选中 再选中全部
          for (let item of this.matklGroup) {
            item.isActive = false;
          }
          this.matklGroup[itemIdx].isActive = true;
          this.filterFrom.materialGroupId = '';
          this.preperListShow = [];
          this.filterFrom = { ...this.filterFrom , pageNo: 1 };
          this.getProductList();
          this.$forceUpdate()
        //   获取数据
        }else {
          this.matklGroup[0].isActive = false;
          for (let item of this.matklGroup) {
            item.isActive = false;
          }
          this.matklGroup[itemIdx].isActive = true;

          this.filterFrom.materialGroupId = this.matklGroup[itemIdx].id
          this.materialGroupCode = this.matklGroup[itemIdx].code,
          this.preperListShow = [];
          this.filterFrom = { ...this.filterFrom , pageNo: 1 };
          this.getProductList();
        }
      }
    },
    // 获取免运费提示
    getMessage() {
      const msg =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14187495683'
      })
      this.cartInfoTip = msg.msgDesc
    },
    //获取商品列表
    async getProductList(type) {

      this.pageLoadFlag = true
      try {
        this.showList = false;
        const res = await findPurchaseProductPage(this.filterFrom);
        if(res.data.code == 1){
          this.preperList = []
          this.preperListShow = []
          this.total = 0
          this.totalPages = 0
          this.pageLoadFlag = false
          this.showList = true;
          return
        }
        const { totalRows, content } = res.data.data;
        if (type == "contract") {
          this.preperList = this.preperList.concat(content) || [];
        } else {
          this.preperList = content || [];
        }
        this.total = totalRows;
        this.totalPages = res.data.data.totalPage;
        this.pageLoadFlag = false;
        this.filterFrom.pageNo += 1;
        this.showList = true;
        this.$nextTick(() => {
          this.handleScroll();
        });
      } catch (error) {
        this.pageLoadFlag = false;
        console.log(error);
      }
    },
    // onFilterChange(checkedObj) {
    //   //合并入参请求对象
    //   this.filterFrom = { ...this.filterFrom, ...checkedObj[0], pageNo: 1 };
    //   //查询列表
    //   this.getProductList();
    // },
    onSearch(searchStr) {
      this.preperList = [];
      this.filterFrom = { ...this.filterFrom, searchStr, pageNo: 1 };
      this.getProductList();
    },
    prodValid(item){
      if(!item.price){
       this.$nextTick(()=>{
         item.userBuy = 0
       })
        this.$message.warning('该商品没有提货价，无法下单！');
        return
      }
    },
    //筛选排序
    sortClick(data) {
      const { sortStatus } = data;
      this.filterFrom = {
        ...this.filterFrom,
        sortType: sortStatus == 0 ? "" : sortStatus == 2 ? "asc" : "desc",
        sortName:data.sortName,
        pageNo: 1
      };
      this.getProductList();
    },

    // 商品数据重构
    getNewProductList(data) {
      let jmap = {};
      let newProduct = [];
      data.forEach(function(item) {
        var key = item.fwOrgName + "_" + item.matklName;
        if (typeof jmap[key] === "undefined") {
          jmap[key] = [];
        }
        item["userBuy"] = 0;
        jmap[key].push(item);
      });
      let keys = Object.keys(jmap);
      for (let i = 0; i < keys.length; i++) {
        let rs = keys[i].split("_");
        newProduct.push({
          productModel: rs[0],
          matklName: rs[1],
          chooseProductNum: 0,
          chooseProductPrice: 0,
          chooseProductClass: 0,
          chooseProductVolume: 0,
          checkChoose: false,
          checkAll: false,
          value: jmap[keys[i]]
        });
      }
      return newProduct;
    },

    handleSubmit() {
      if (this.allnum == 0) {
        // this.$message.warning("商品数量不可为0");
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14180000056'
        })
        this.$message.warning(msg&&msg.msgDesc);
        return false;
      }
      let params = {
      };
      const ids = [];
      const counts = [];
      let agentAndSupplier;
      let orgId;
      let materialGroupId;
      let flag = false;
      const productModels = []
      let productModelFlag = false
      let productInfoQueryList = []
      let cherkArr = this.preperList.filter(it => it.userBuy && it.userBuy > 0)
      this.preperList.forEach(item => {
        if (item.userBuy && item.userBuy > 0) {
          if (agentAndSupplier && agentAndSupplier != item.agentAndSupplier) {
            flag = true;
          }
          if (materialGroupId && materialGroupId != item.materialGroupId) {
            flag = true;
          }
          agentAndSupplier = item.agentAndSupplier;
          materialGroupId = item.materialGroupId;
          orgId = item.orgId
          ids.push(item.id);
          counts.push(item.userBuy);
          productInfoQueryList.push({
            productInfoId:item.productInfoId,
            quantity:item.userBuy
          })
        }
      });
      if(flag){
        // this.$message.warning("请选择同一供应商同一物料组商品！");
        // const msg =  this.$configTotal({
        //   route: this.$route.fullPath,
        //   id:'14180000057'
        // })
        this.mesageInfoTips = '目前不支持跨供应商-物料组下单，请重新选择！'
        this.ChannelOrderTip = true
        // this.$message.warning(msg&&msg.msgDesc);
        return false;
      }

      // 跳转页面
      this.$router.push({
        path: "/prototyZone/sumbitOrder",
        query:{
          orgId: cherkArr[0].orgId,
          shopInfoId: this.filterFrom.shopInfoId,
          shopCisCode: this.shopCisCode,
          type: 'yangji',
          operateFlag:cherkArr[0].operateFlag,
          materialGroupCode:cherkArr[0].materialGroupCode,
          agentAndSupplier: agentAndSupplier,
          productInfoQueryList:JSON.stringify(productInfoQueryList)
        }
      });
    },

    handlePageChange() {
      this.getNewProductList();
    },

    //查看已选
    checkHasChoose(index) {
      this.preperList[index] = {
        ...this.preperList[index],
        checkChoose: this.preperList[index].checkChoose
      };
      this.$forceUpdate();
    },

    //全选
    CheckAllEdit(index) {
      if (this.preperList[index].checkAll) {
        this.preperList[index].value.forEach(item => {
          item.userBuy == 0 ? (item.userBuy += 1) : item.userBuy;
        });
      } else {
        this.preperList[index].value.forEach(item => {
          item.userBuy = 0;
        });
      }
      this.dataChange(index);
      this.$forceUpdate();
    },

    //获取物流提示
    getInfo() {
      this.$info({
        title: "体积提示",
        content: this.$store.state.user.getVolumnText
      });
    },

    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    }
  },
  mounted() {
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    // this.filterFrom = { ...this.filterFrom, formCode:this.filterConfigStr ? this.filterConfigStr: '' };
    //默认查询入参
    // if (this.$route.query.condition) {
    //   this.filterFrom.condition = this.$route.query.condition;
    // }
    //最后一项面包屑显示
    let breadcrumbLast = {
      title: this.$route.query.customName
        ?  this.$route.query.customName
        : "",
    };
    //最后一项面包屑
    if (breadcrumbLast.title) {
      this.$set(this.breadcrumbData, this.breadcrumbData.length, breadcrumbLast);
    }
    window.addEventListener(
      "scroll",
      this.throttle(this.handleScroll, 10),
      true
    );
    this.getMessage()
  }
};
