import http from "@/utils/request";

// 获取门店列表
export function getPrototypeShopList(params) {
  return http({
    url: '/prototype/findPrototypeShopList.nd',
    method: 'get',
    params
  })
}
// 根据门店获取物料组
export function getMaterialGroupList(params) {
  return http({
    url: '/prototype/findMaterialGroupList.nd',
    method: 'get',
    params
  })
}

